import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTabIndex } from 'react-tabindex';
import useLocalStorage from "../Hooks/useLocalStorage";
import postSubmitAPI from "../API/Submission";
import { ErrorBox } from "./ErrorBox";
import {
    localStorageGetDateItem,
    localStorageSetObjectOrArrayItem,
    localStorageGetObjectItem
} from "../Helpers/localStorageUtility";
import getBrowserMetrics from "../Helpers/browserMetricsHelper";

function UploadAnswerSheet() {
    const { t, i18n } = useTranslation();
    const tabIndex = useTabIndex();
    const [showDownloadSection, setShowDownloadSection] = useState(false);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [forceUrl, setForceUrl] = useState("");
    const [submitURL] = useLocalStorage("submitURL");
    const [content] = useLocalStorage("content", {});
    const [multipleChoiceContent] = useState(localStorageGetObjectItem("multiple_choice_content"), {});
    const [textareaContent] = useLocalStorage("textarea_content", {});
    const [tenantID] = useLocalStorage("tenantID");
    const [evaluationUUID] = useLocalStorage("uuid");
    const [studentID] = useLocalStorage("studentID");
    const [studentName] = useLocalStorage("studentName");
    const [studentCode] = useLocalStorage("studentCode");
    const [schoolName] = useLocalStorage("schoolName");
    const [schoolCode] = useLocalStorage("schoolCode");
    const [city] = useLocalStorage("city");
    const [grade] = useLocalStorage("grade");
    const [year] = useLocalStorage("year");
    const [numberOfAreas] = useLocalStorage("numberOfAreas");
    const [durationInMinutes] = useLocalStorage("durationInMinutes");
    const [appLanguage] = useLocalStorage("appLanguage");
    const [customID] = useLocalStorage("customID");
    const [category] = useLocalStorage("category");

    // The following code will start a `timeout` of 3 seconds before showing
    // the download receipt section.
    const showDownloadSectionTimeout = () => {
        setTimeout(() => {
            console.log("Uploading taking too long, enabling download section now.");
            setShowDownloadSection(true);
        }, 3000);
    };

    useEffect(() => {
        console.log("Uploading...");
        document.title = "Uploading...";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // Run the code of showing the download section after a certain amount of time has elapsed.
        showDownloadSectionTimeout();

        // DEVELOPERS NOTE: Change the language.
        i18n.changeLanguage(appLanguage);

        let mounted = true;

        const startDT = localStorageGetDateItem("startDT");
        const finishDT = localStorageGetDateItem("finishDT");

        let choicesArr = []
        for (let itemId in multipleChoiceContent) {
            let item = multipleChoiceContent[itemId];
            choicesArr.push(item);
        }

        let textArr = []
        for (let itemId in textareaContent) {
            let item = textareaContent[itemId];
            textArr.push(item);
        }

        let answersArr = [];
        for (let choice of choicesArr) {
            answersArr.push(choice)
        }
        for (let choice of textArr) {
            answersArr.push(choice)
        }

        localStorageSetObjectOrArrayItem("answers", answersArr); // Save

        const browserMetrics = getBrowserMetrics(); // Get browser metrics to attach.

        const postData = {
            // multiple_choice_answers: choicesArr,
            // textarea_answers: textArr,
            tenant_id: tenantID,
            answers: answersArr,
            evaluation_uuid: `${evaluationUUID}`,
            school_name: `${schoolName}`,
            school_code: `${schoolCode}`,
            student_id: `${studentID}`,
            student_code: `${studentCode}`,
            student_name: `${studentName}`,
            start_dt: startDT.getTime(),
            finish_dt: finishDT.getTime(),
            submit_url: submitURL, // We only do this for the receipt!
            city: `${city}`,
            grade: Math.abs(parseInt(grade)),
            year: Math.abs(parseInt(year)),
            number_of_areas: Math.abs(parseInt(numberOfAreas)),
            duration_in_minutes: Math.abs(parseInt(durationInMinutes)),
            custom_id: String(customID),
            category: category,
            browser_metrics: {
                "app_code_name": browserMetrics.appCodeName,
                "app_name": browserMetrics.appName,
                "app_version": browserMetrics.appVersion,
                "cookie_enabled": browserMetrics.cookieEnabled,
                "language": browserMetrics.language,
                "platform": browserMetrics.platform,
                "product": browserMetrics.product,
                "user_agent": browserMetrics.userAgent,
                "screen_avail_height": browserMetrics.screenAvailHeight,
                "screen_avail_width": browserMetrics.screenAvailWidth,
                "screen_color_depth": browserMetrics.screenColorDepth,
                "screen_height": browserMetrics.screenHeight,
                "screen_width": browserMetrics.screenWidth,
                "frontend_version": browserMetrics.frontendVersion,
            },
        };
        localStorageSetObjectOrArrayItem("RECEIPT_PAYLOAD", postData);
        postSubmitAPI(submitURL, postData, (responseData) => {
            if (mounted) {
                setForceUrl("/complete");
            }
        }, (apiErrors) => {
            if (mounted) {
                setErrors(apiErrors);
            }
        }, () => {
            setData(postData);
        });

        return () => mounted = false;
    }, [submitURL, content, tenantID, evaluationUUID, schoolCode, schoolName, studentCode, studentName, city, customID, category, durationInMinutes, grade, multipleChoiceContent, numberOfAreas, studentID, textareaContent, year, appLanguage, i18n])

    // If the component has a `forceUrl` set then that means we can move onto
    // the next page and submit our scantron results to the API server.
    if (forceUrl !== "") {
        return <Navigate to={forceUrl}/>;
    }

    function onFormSubmitClick(e) {
        const codePayloadStr = JSON.stringify(data);
        const codePayloadB64 = btoa(unescape(encodeURIComponent(codePayloadStr)));

        const name = "receipt";
        const fileName = name.replace(/\s+/g, '-').toLowerCase() + ".txt";
        const fileContent = codePayloadB64;

        // SOURCE: https://thewebdev.info/2021/11/20/how-to-download-a-string-as-txt-file-in-react/
        const element = document.createElement("a");
        const file = new Blob([fileContent], {type: "text/plain"});
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m6">
                    <ErrorBox errors={errors} />
                    <div style={{width:"100%"}} className="w3-margin-top">
                        <div>
                            <div className="w3-white w3-center w3-padding w3-round-xlarge w3-border w3-border-grey">
                                <i className="fa fa-spinner w3-spin w3-jumbo"></i>
                                <h1><strong>{t('Sending, please wait...')}</strong></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w3-col m3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
            </div>


            {showDownloadSection &&
                <>
                    <div className="w3-row w3-margin-top">
                        <div className="w3-col m3 w3-container">
                            {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                        </div>
                        <div className="w3-col m6">
                            <ErrorBox errors={errors} />
                            <div style={{width:"100%"}} className="w3-margin-top">
                                <div>
                                    <div className="w3-white w3-center w3-padding w3-round-xlarge w3-border w3-border-grey">

                                        <p className="w3-row">
                                            <div className="w3-col w3-center">
                                                <h3>
                                                    <strong>
                                                        <i className="fa fa-file-text w3-text-black"></i>&nbsp;{t('RECEIPT CODE')}
                                                    </strong>
                                                </h3>
                                            </div>
                                            <div className="w3-col m1 w3-container">
                                                <span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                                            </div>
                                            <div className="w3-col m10 w3-center">
                                            <p className="w3-text-black">
                                                If you are experiencing any issues, you may download this receipt as proof that you completed the evaluation and hand in to the teacher at a later date.
                                            </p>
                                                <button className="w3-btn w3-green w3-round-xlarge"
                                                          onClick={onFormSubmitClick}
                                                             type="button"
                                                         tabIndex={tabIndex}>
                                                    <i className="fa fa-download" aria-hidden="true"></i>&nbsp;{t('DOWNLOAD')}
                                                </button>
                                                {/*
                                                    <a href={qrCodeURL} target="_blank" rel="noreferrer" tabIndex={tabIndex}>
                                                    {qrCodeURL.length < 2900
                                                        ? <QRCode value={qrCodeURL} size={400} className="w3-image w3-border" />
                                                        : <>Receipt Link</>
                                                    }
                                                    </a>
                                                */}
                                                </div>
                                            <div className="w3-col m1 w3-container">
                                                <span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                                            </div>

                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w3-col m3 w3-container">
                            {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                        </div>
                    </div>

                    <div className="w3-row w3-margin-top">
                    </div>
                </>
            }

        </>
    );
}

export default UploadAnswerSheet;
