import React, { useState, useEffect } from "react";
import postSubmitAPI from "../API/Submission";
import { useTabIndex } from 'react-tabindex';
import { ErrorBox } from "./ErrorBox";
import { isEmpty } from 'lodash';
import { Base64 } from 'js-base64';

function ReceiptSubmitter() {
    const tabIndex = useTabIndex();
    const [errors, setErrors] = useState({});
    const [qrCode, setQrCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        document.title = "Receipt Submitter";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    function onQRCodeChange(e) {
        setQrCode(e.target.value);
    }

    function onFormSubmitClick(e) {
        console.log("onFormSubmitClick");
        const codeBase64 = qrCode
        let codeString, codeData, submitURL;
        let hasError = false;

        // Convert the `base64` value into our `string` value or error.
        if (codeBase64 === undefined || codeBase64 === null) {
            hasError = true;
            setErrors("Missing `code` parameter.");
        } else if (codeBase64 === "") {
            hasError = true;
            setErrors("`code` parameter is missing content.");
        } else {
            codeString = Base64.decode(codeBase64);
        }

        // Convert the `string` value into our `dictionary` or error.
        if (hasError === false) {
            try {
                codeData = JSON.parse(codeString)
            } catch(e) {
                hasError = true;
                setErrors("Code content is corrupt.");
            }
        }

        // Extract the required `key` and `value` pairs necessary to run our application or error.
        submitURL = codeData["submit_url"];
        if (submitURL === undefined) {
            hasError = true;
            setErrors("Code content is missing `submit_url` key and value.");
        }

        setIsLoading(true);

        console.log("Saving info form...");
        postSubmitAPI(
            submitURL,
            codeData,
            (responseData) => {
                console.log("postReceiptAPI|responseData:", responseData);
                setIsSubmitted(true);
            }, (apiErrors) => {
                console.log("postReceiptAPI|apiErrors:", apiErrors);
                setErrors(apiErrors);
            }, () => {
                console.log("postReceiptAPI|done");
                setIsLoading(false);
            }
        );
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m3 w3-container"></div>
                <div className="w3-col m6">
                    <ErrorBox errors={errors} />
                    <>
                        {isSubmitted
                            ? <>
                                {isLoading
                                    ? <LoadingSection />
                                    : <>
                                        {isEmpty(errors)
                                            ? <SuccessSection />
                                            : <ErrorSection errors={errors} />
                                        }
                                    </>
                                }
                            </>
                            : <>
                                <QRCodeInputSection
                                    errors={errors}
                                    qrcode={qrCode}
                                    onQRCodeChange={onQRCodeChange}
                                    tabIndex={tabIndex}
                                    onFormSubmitClick={onFormSubmitClick}
                                />
                            </>
                        }
                    </>
                </div>
                <div className="w3-col m3 w3-container"></div>
            </div>

        </>
    );
}

function QRCodeInputSection(props) {
    const { errors, qrcode, onQRCodeChange, tabIndex, onFormSubmitClick } = props;
    return (
        <>
            <div className="w3-row w3-panel">
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l6 w3-container">
                    <div className="w3-container w3-white w3-round w3-border w3-border-grey w3-card">
                        <h1 className="w3-center w3-text-blue">QR Code Submit</h1>
                        <form className="w3-container">
                            <ErrorBox errors={errors} />
                            <p>Please enter all the required fields (*) before beginning the evaluation.</p>
                            <p className={`w3-row ${errors && errors.qrcode ? "w3-leftbar w3-border-red w3-padding" : ""}`}>
                                <label className="w3-col">
                                    <strong>QR Code&nbsp;(*)</strong>
                                </label>
                                {errors && errors.qrcode && <label className="w3-col">
                                    {<strong className="w3-text-red">{errors.qrcode}</strong>}
                                </label>}
                                <textarea className={`w3-col m6 w3-input w3-border ${errors.qrcode ? 'w3-border-red' : 'w3-border-black'}`}
                                            type="text"
                                        disabled={false}
                                            style={{width:"100%"}}
                                            cols={35}
                                            name="qrcode"
                                           value={qrcode}
                                        onChange={onQRCodeChange}
                                     placeholder={'Please fill student name.'}
                                        tabIndex={tabIndex}
                                />
                            </p>


                            <p className="w3-row">
                                <hr className="w3-col w3-grey" />
                                {/*

                            <p className="w3-row">
                                <hr className="w3-col w3-grey" />
                                {/*
                                <Link className="w3-col m5 w3-btn w3-orange w3-round-xlarge w3-left" to="/info"><i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;BACK</Link>
                                */}
                                <span className="w3-col m6 w3-container"></span>
                                <button className="w3-col m6 w3-btn w3-teal w3-round-xlarge w3-right"
                                          onClick={onFormSubmitClick}
                                             type="button"
                                         tabIndex={tabIndex}>
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;{'SUBMIT'}
                                </button>
                            </p>

                        </form>
                    </div>
                </div>
                <div className="w3-col m1 l3 w3-container">

                </div>
            </div>
        </>
    );
}

function LoadingSection(props) {
    <div style={{width:"100%"}} className="w3-margin-top">
        <div>
            <div className="w3-white w3-center w3-padding w3-round-xlarge w3-border w3-border-grey">
                <i className="fa fa-spinner w3-spin w3-jumbo"></i>
                <h1><strong>Sending receipt, please wait...</strong></h1>
            </div>
        </div>
    </div>
}

function ErrorSection({ errors }) {
    return (
        <div class="w3-panel w3-pale-red w3-round-xlarge">
            <h3>Oops, looks like something went wrong...</h3>
            <p>We appoligies but there appears to be a problem on our end.</p>
            <ErrorBox errors={errors} />
        </div>
    );
}

function SuccessSection(props) {
    return (
        <div className="w3-row w3-margin-top">
            <div className="w3-col m3 w3-container"></div>
            <div className="w3-col m6">
                <div style={{width:"100%"}} className="w3-margin-top">
                    <div>
                        <div className="w3-white w3-center w3-padding w3-round-xlarge w3-border w3-border-grey">
                            <i className="fa fa-check-circle w3-jumbo w3-text-green"></i>
                            <h1><strong>Receipt Submitted</strong></h1>
                            <p>
                            You have successfully submitted your receipt. Our system has received your evaluation and will proceed to mark it.
                            </p>
                            <p>
                            Thank you and have a great day!
                            </p>
                            <p>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w3-col m3 w3-container"></div>
        </div>
    )
}

export default ReceiptSubmitter;
