import React, { useEffect, useState }  from "react";
import { Navigate } from 'react-router-dom';
import { useTabIndex } from 'react-tabindex';
import { useTranslation } from 'react-i18next';
import useLocalStorage from "../Hooks/useLocalStorage";
import {
    localStorageGetDateItem, localStorageGetObjectItem
} from "../Helpers/localStorageUtility";

function Complete() {
    const { t, i18n } = useTranslation();
    const [forceUrl, setForceUrl] = useState("");
    const tabIndex = useTabIndex();
    const [name] = useLocalStorage("name");
    const [alias] = useLocalStorage("alias");
    const [questionsCount] = useLocalStorage("questionsCount");
    const startDT = localStorageGetDateItem("startDT");
    const finishDT = localStorageGetDateItem("finishDT");
    const [studentName] = useLocalStorage("studentName", "");
    const [studentCode] = useLocalStorage("studentCode", "");
    const [schoolName] = useLocalStorage("schoolName", "");
    const [schoolCode] = useLocalStorage("schoolCode", "");
    const [content] = useLocalStorage("content", {});
    const [answers] = useLocalStorage("answers", []);
    const [appLanguage] = useLocalStorage("appLanguage");

    useEffect(() => {
        document.title = "Completed";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // DEVELOPERS NOTE: Change the language.
        i18n.changeLanguage(appLanguage);
    },[i18n, appLanguage]);

    // Generate our receipt QR code.
    const codePayload = localStorageGetObjectItem("RECEIPT_PAYLOAD");
    const codePayloadStr = JSON.stringify(codePayload);
    const codePayloadB64 = btoa(unescape(encodeURIComponent(codePayloadStr)));
    const qrCodeURL = process.env.REACT_APP_WWW_PROTOCOL + "://" + process.env.REACT_APP_WWW_DOMAIN +"/receipt?code="+codePayloadB64;

    console.log("LENGTH:", qrCodeURL.length);
    console.log("content", content);
    console.log("answers", answers);
    console.log("codePayload", codePayload);

    function onFormSubmitClick(e) {
        const fileName = name.replace(/\s+/g, '-').toLowerCase() + ".txt";
        const fileContent = codePayloadB64;

        // SOURCE: https://thewebdev.info/2021/11/20/how-to-download-a-string-as-txt-file-in-react/
        const element = document.createElement("a");
        const file = new Blob([fileContent], {type: "text/plain"});
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
    }

    function receiptButtonClick(e) {
        console.log("receiptButtonClick clicked");
        localStorage.setItem("codePayloadB64", codePayloadB64);
        setForceUrl("/receipt");
    }

    // If the component has a `forceUrl` set then that means we can move onto
    // the next page and submit our scantron results to the API server.
    if (forceUrl !== "") {
        return <Navigate to={forceUrl}/>;
    }

    return (
        <>
            <div className="w3-panel w3-row">
                <div className="w3-col m3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m6">
                    <div className="w3-white w3-border w3-border-grey w3-round-xlarge w3-card-4" style={{width:"100%",}}>
                        <div className="w3-container w3-padding">
                            <div className="w3-center">
                                <h1><strong>
                                       <i className="fa fa-check-circle w3-text-green"></i>&nbsp;{t('Submitted')}
                                </strong></h1>
                            </div>
                            <p className="w3-text-grey">{t('You have successfully sent your answer sheet to the server. Please print a copy of this page for your personal records.')}</p>
                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Date Submitted')}</strong>:&nbsp;{finishDT.toUTCString()}</p>

                            <hr className="w3-grey" style={{height:"2px",}} />

                            <p className="w3-text-grey"><strong className="w3-text-black">{t('School')}</strong>:&nbsp;{schoolName}&nbsp;(<i>#{schoolCode}</i>)</p>
                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Student')}</strong>:&nbsp;{studentName}&nbsp;(<i>#{studentCode}</i>)</p>

                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Evaluation')}</strong>:&nbsp;{name} - {alias}</p>
                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Started')}</strong>:&nbsp;{startDT.toUTCString()}</p>
                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Finished')}</strong>:&nbsp;{finishDT.toUTCString()}</p>
                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Total Questions')}</strong>:&nbsp;{questionsCount}</p>
                            <p className="w3-text-grey"><strong className="w3-text-black">{t('Answers')}</strong>:&nbsp;
                            </p>
                            {/*
                            {content && Object.entries(content).map(([key, value]) => (
                                <p className="w3-text-grey w3-small"><b>{key}.</b>&nbsp;{value.slice(0, 1000)}&nbsp;<b>{value.length > 1000 && ". . ."}</b></p>
                            ))}
                            */}

                            {answers && answers.map((c) => (
                                <>
                                {c.type === 1
                                    ? <p className="w3-text-grey w3-small"><b>{c.question_number}.</b>&nbsp;{c.picked_letter.slice(0, 1000)}&nbsp;<b>{c.picked_letter.length > 1000 && ". . ."}</b></p>
                                    : <p className="w3-text-grey w3-small"><b>{c.question_number}.</b>&nbsp;{c.picked_text.slice(0, 1000)}&nbsp;<b>{c.picked_text.length > 1000 && ". . ."}</b></p>
                                }
                                </>
                            ))}

                            {/* https://www.geeksforgeeks.org/maximum-length-of-a-url-in-different-browsers/ */}

                            <hr className="w3-grey" style={{height:"2px",}} />
                            {qrCodeURL.length < 80000 &&
                                <p className="w3-row">
                                    <div className="w3-col w3-center">
                                        <h3>
                                            <strong>
                                                <i className="fa fa-file-text w3-text-black"></i>&nbsp;{t('RECEIPT CODE')}
                                            </strong>
                                        </h3>
                                    </div>
                                    <div className="w3-col m1 w3-container">
                                        <span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                                    </div>
                                    <div className="w3-col m10 w3-center">
                                        <button className="w3-btn w3-green w3-round-xlarge"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-download" aria-hidden="true"></i>&nbsp;{t('DOWNLOAD')}
                                        </button>
                                        {/*
                                            <a href={qrCodeURL} target="_blank" rel="noreferrer" tabIndex={tabIndex}>
                                            {qrCodeURL.length < 2900
                                                ? <QRCode value={qrCodeURL} size={400} className="w3-image w3-border" />
                                                : <>Receipt Link</>
                                            }
                                            </a>
                                        */}
                                        </div>
                                    <div className="w3-col m1 w3-container">
                                        <span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                                    </div>
                                    <div className="w3-col w3-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={receiptButtonClick}>
                                        <p className="w3-text-black">
                                            <strong>{t('CLICK THIS TO SUBMIT ELECTRONICALLY')}</strong>&nbsp;<i className="fa fa-external-link"></i>
                                        </p>
                                    </div>
                                </p>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m3 w3-container">
                </div>
            </div>
        </>
    );
}

export default Complete;
