import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useTabIndex } from 'react-tabindex';
import { useTranslation } from 'react-i18next';
import useLocalStorage from "../Hooks/useLocalStorage";
import { ErrorBox } from "./ErrorBox";
import { localStorageSetObjectOrArrayItem } from "../Helpers/localStorageUtility";


function IdentificationSheet() {
    const tabIndex = useTabIndex();
    const { t, i18n } = useTranslation();
    const [studentID] = useLocalStorage("studentID", "");
    const [studentName, setStudentName] = useLocalStorage("studentName", "");
    const [studentCode, setStudentCode] = useLocalStorage("studentCode", "");
    const [schoolName, setSchoolName] = useLocalStorage("schoolName", "");
    const [schoolCode, setSchoolCode] = useLocalStorage("schoolCode", "");
    const [errors, setErrors] = useState({});
    const [forceURL, setForceURL] = useState("");
    const [appLanguage] = useLocalStorage("appLanguage");

    useEffect(() => {
        document.title = "My Identification";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // DEVELOPERS NOTE: Change the language.
        i18n.changeLanguage(appLanguage);
    },[appLanguage, i18n]);

    function onStudentNameChange(e) {
        setStudentName(e.target.value);
    }

    function onStudentCodeChange(e) {
        setStudentCode(e.target.value);
    }

    function onSchoolNameChange(e) {
        setSchoolName(e.target.value);
    }

    function onSchoolCodeChange(e) {
        setSchoolCode(e.target.value);
    }

    function onFormSubmitClick(e) {
        let err = {};
        console.log("Saving info form...");
        if (studentName === "") {
            err["studentName"] = "Missing value."
        }
        if (studentCode === "") {
            err["studentCode"] = "Missing value."
        }
        if (schoolName === "") {
            err["schoolName"] = "Missing value."
        }
        if (schoolCode === "") {
            err["schoolCode"] = "Missing value."
        }
        if (Object.keys(err).length > 0) {
            setErrors(err);
            return;
        } else {
            localStorageSetObjectOrArrayItem("startDT", new Date());
            setForceURL("/answer-sheet");
        }
    }

    if (forceURL !== "") {
        return <Navigate to={forceURL} />;
    }

    return (
        <>
            <div className="w3-row w3-panel">
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l6 w3-container">
                    <div className="w3-container w3-white w3-round w3-border w3-border-grey w3-card">
                        <h1 className="w3-center w3-text-blue">{t('My Identification')}</h1>
                        <form className="w3-container">
                            <ErrorBox errors={errors} />
                            <p>{t('Please enter all the required fields (*) before beginning the evaluation.')}</p>
                            <p className={`w3-row ${errors && errors.studentName ? "w3-leftbar w3-border-red w3-padding" : ""}`}>
                                <label className="w3-col">
                                    <strong>{t('Student Name')}&nbsp;(*)</strong>
                                </label>
                                {errors && errors.studentName && <label className="w3-col">
                                    {<strong className="w3-text-red">{errors.studentName}</strong>}
                                </label>}
                                <input className={`w3-col m6 w3-input w3-border ${errors.studentName ? 'w3-border-red' : 'w3-border-black'}`}
                                            type="text"
                                        disabled={studentID !== ""}
                                            name="studentName"
                                           value={studentName}
                                        onChange={onStudentNameChange}
                                     placeholder={`${t('Please fill student name.')}`}
                                        tabIndex={tabIndex}
                                />
                            </p>
                            <p className={`w3-row ${errors && errors.studentCode ? "w3-leftbar w3-border-red w3-padding" : ""}`}>
                                <label className="w3-col">
                                    <strong>{t('Student Code')}&nbsp;(*)</strong>
                                </label>
                                {errors && errors.studentCode && <label className="w3-col">
                                    {<strong className="w3-text-red">{errors.studentCode}</strong>}
                                </label>}
                                <input className={`w3-col m4 w3-input w3-border ${errors.studentCode ? 'w3-border-red' : 'w3-border-black'}`}
                                            type="text"
                                        disabled={studentID !== ""}
                                            name="studentCode"
                                           value={studentCode}
                                        onChange={onStudentCodeChange}
                                     placeholder={`${t('Please fill student code.')}`}
                                        tabIndex={tabIndex}
                                />
                            </p>
                            <p className={`w3-row ${errors && errors.schoolName ? "w3-leftbar w3-border-red w3-padding" : ""}`}>
                                <label className="w3-col">
                                    <strong>{t('School Name')}&nbsp;(*)</strong>
                                </label>
                                {errors && errors.schoolName && <label className="w3-col">
                                    {<strong className="w3-text-red">{errors.schoolName}</strong>}
                                </label>}
                                <input className={`w3-col m6 w3-input w3-border ${errors.schoolName ? 'w3-border-red' : 'w3-border-black'}`}
                                            type="text"
                                        disabled={studentID !== ""}
                                            name="schoolName"
                                           value={schoolName}
                                        onChange={onSchoolNameChange}
                                     placeholder={`${t('Please fill school name.')}`}
                                        tabIndex={tabIndex}
                                />
                            </p>
                            <p className={`w3-row ${errors && errors.schoolCode ? "w3-leftbar w3-border-red w3-padding" : ""}`}>
                                <label className="w3-col">
                                    <strong>{t('School Code')}&nbsp;(*)</strong>
                                </label>
                                {errors && errors.schoolCode && <label className="w3-col">
                                    {<strong className="w3-text-red">{errors.schoolCode}</strong>}
                                </label>}
                                <input className={`w3-col m4 w3-input w3-border ${errors.schoolCode ? 'w3-border-red' : 'w3-border-black'}`}
                                            type="number"
                                        disabled={studentID !== ""}
                                            name="schoolCode"
                                           value={schoolCode}
                                        onChange={onSchoolCodeChange}
                                     placeholder={`${t('Please fill school code.')}`}
                                        tabIndex={tabIndex}
                                />
                            </p>

                            <p className="w3-row">
                                <hr className="w3-col w3-grey" />
                                {/*

                            <p className="w3-row">
                                <hr className="w3-col w3-grey" />
                                {/*
                                <Link className="w3-col m5 w3-btn w3-orange w3-round-xlarge w3-left" to="/info"><i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;BACK</Link>
                                */}
                                <span className="w3-col m6 w3-container"></span>
                                <button className="w3-col m6 w3-btn w3-teal w3-round-xlarge w3-right"
                                          onClick={onFormSubmitClick}
                                             type="button"
                                         tabIndex={tabIndex}>
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;{t('SAVE AND CONTINUE')}
                                </button>
                            </p>

                        </form>
                    </div>
                </div>
                <div className="w3-col m1 l3 w3-container">

                </div>
            </div>
        </>
    );
}

export default IdentificationSheet;
