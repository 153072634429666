import React from "react";
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';


export function ErrorBox(props) {
    const { t } = useTranslation();
    const { errors } = props;

    // STEP 1: Check to see if we have ANY errors returned from the API
    //         web-service and if no errors were returned then our stateless
    //         component will return nothing.
    if (errors === null || errors === undefined) { return null; }
    if (Object.keys(errors).length === 0) { return null; }

    let array = [];

    if (typeof errors === "string") {
        array.push(
            <p className="w3-text-red">
                <strong>Error(s):</strong>&nbsp;{errors}
            </p>
        );
    } else {
        // STEP 2: Iterate through all the "fields" and "non_field_errors" that
        //         our Django REST application returned.
        Object.keys(errors).forEach(key => {
            // STEP 3: Process a single "field" or "non_field_errors" and
            //         then get our value.
            let startKey = startCase(key);

            // DEVELOPERS NOTE:
            // The following code will remove any "Id" related keys as it was added
            // due to "Golang" naming convention in the database. Ex: `how_hear_id`.
            // console.log("Info | BootstrapErrorsProcessingAlert | startKey | original:",startKey);
            startKey = startKey.replace(" Id", "");
            // console.log("Info | BootstrapErrorsProcessingAlert | startKey | modified:",startKey);

            let value = errors[key];
            // console.log(key, value); // For debugging purposes only.

            // STEP 4: Generate the error row if the value accomponying it is not blank.
            if (value !== undefined && value !== null) {
                array.push(
                    <p className="w3-text-red">
                        <strong>{startKey}:</strong>&nbsp;{value}
                    </p>
                );
            }
        });
    }

    // STEP 5: Render our processed error list.
    return (
        <div className="w3-panel w3-leftbar w3-rightbar w3-topbar w3-bottombar w3-border-red">
            <h3 className="alert-heading"><strong>{t('There is a problem')}</strong></h3>
            {array}
            <hr />
            <p>{t('Please make sure the above error(s) have been fixed before submitting again')}</p>
        </div>
    );
}
