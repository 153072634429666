import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useTabIndex } from 'react-tabindex';
import { useTranslation } from 'react-i18next';
import useLocalStorage from "../Hooks/useLocalStorage";


function EvaluationInfo() {
    const { t, i18n } = useTranslation();
    const tabIndex = useTabIndex();
    const [name] = useLocalStorage("name");
    const [alias] = useLocalStorage("alias");
    const [imageURL] = useLocalStorage("imageURL");
    const [description] = useLocalStorage("description");
    const [appLanguage] = useLocalStorage("appLanguage");

    useEffect(() => {
        document.title = "Evaluation Info";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // DEVELOPERS NOTE: Change the language.
        i18n.changeLanguage(appLanguage);
    },[appLanguage, i18n]);

    return (
        <>
            <div className="w3-row w3-panel">
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l6 w3-container">
                    <div className="w3-container w3-white w3-round w3-border w3-border-grey w3-card">
                        <h1 className="w3-center w3-text-blue">{name}</h1>
                        <h3 className="w3-center w3-text-grey">{alias}</h3>
                        <div className="w3-center">
                            <img src={imageURL}
                             className="w3-image w3-round-xxlarge"
                                   alt="Thumbnail of evaluation."
                                 style={{width:"250px"}}
                            />
                        </div>
                        <div className="w3-row w3-margin">
                            <div className="w3-col m1 w3-container">
                                {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                            </div>
                            <div className="w3-col m10 w3-container">
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                            <div className="w3-col m1 w3-container">
                                {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                            </div>
                        </div>
                        <div className="w3-container w3-center w3-margin">
                            <Link className="w3-btn w3-mobile w3-teal w3-round-large w3-padding"
                                         to="/id"
                                   tabIndex={tabIndex}>{t('Next')}&nbsp;<i className="fa fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l3 w3-container">

                </div>
            </div>
        </>
    );
}

export default EvaluationInfo;
