import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLocalStorage from "../Hooks/useLocalStorage";
import { useTabIndex } from 'react-tabindex';
import { ErrorBox } from "./ErrorBox";
import { localStorageSetObjectOrArrayItem, localStorageGetDateItem } from "../Helpers/localStorageUtility";


export default function AnswerSheet() {
    const tabIndex = useTabIndex();
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState({});
    const [name] = useLocalStorage("name");
    const [alias] = useLocalStorage("alias");
    const [forceURL, setForceURL] = useState("");
    const [mcQuestionsCount] = useLocalStorage("mcQuestionsCount");
    const [taQuestionsCount] = useLocalStorage("taQuestionsCount");
    const [optionsCount] = useLocalStorage("optionsCount");
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState({});
    const [textareaAnswers, setTextareaAnswers] = useState({});
    const startDT = localStorageGetDateItem("startDT");
    const [choiceDT, setChoiceDT] = useState(startDT);
    const [appLanguage] = useLocalStorage("appLanguage");

    useEffect(() => {
        document.title = name;
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // DEVELOPERS NOTE: Change the language.
        i18n.changeLanguage(appLanguage);
    },[name, appLanguage, i18n]);

    // Generate an array of a range of all the numbers from 1 to the mcQuestionsCount.
    // Each integer value in this array will be our question ID.
    const start = 1;
    const end = mcQuestionsCount + taQuestionsCount;
    const questionPKs = [...Array(end - start + 1).keys()].map(x => x + start);; // https://jasonwatmore.com/post/2021/10/02/vanilla-js-create-an-array-with-a-range-of-numbers-in-a-javascript

    function onSubmitClick(e) {

        // Defensive Code:
        // We need to make sure the student has finished answering all the questions
        // before proceeding forward with the submission.
        if (Object.keys(multipleChoiceAnswers).length !== mcQuestionsCount) {
            window.scrollTo(0, 0);  // Start the page at the top of the page.
            const errMsg = t('Not all questions were answered, please make sure you have answered all the questions before proceeding to submit the evaluation.');
            setErrors(errMsg);
            return
        }

        // console.log("multipleChoiceAnswers:", multipleChoiceAnswers);
        // console.log("textareaAnswers:", textareaAnswers);
        const content =  Object.assign({}, multipleChoiceAnswers, textareaAnswers);
        localStorageSetObjectOrArrayItem("multiple_choice_content", multipleChoiceAnswers);
        localStorageSetObjectOrArrayItem("textarea_content", textareaAnswers);
        localStorageSetObjectOrArrayItem("content", content);
        localStorageSetObjectOrArrayItem("finishDT", new Date());
        setForceURL("/uploading");
    }

    function onMultipleChoiceQuestionChoiceChange(e, questionPK, choicePK, letter, choiceDT) {
        // console.log(e.target.value);
        // console.log("questionPK:", questionPK);
        // console.log("choicePK:",choicePK);
        // console.log("letter:",letter);
        // console.log("choiceDT:", choiceDT);
        // console.log();

        var multipleChoiceAnswersCopy = {...multipleChoiceAnswers};

        // let ans = Object.assign(multipleChoiceAnswers);
        multipleChoiceAnswersCopy[questionPK] = {
            "question_number": questionPK,
            "type": 1,
            "picked_letter": letter,
            "picked_text": "",
            "started_time": choiceDT.getTime(),
            "finished_time": new Date().getTime(),
        };
        setMultipleChoiceAnswers(multipleChoiceAnswersCopy);

        // Developer Note: We need to set the new start date for the `choiceDT`
        // state so know the start date for each question.
        setChoiceDT(new Date());
    }

    function onTextareaQuestionTextChange(e, questionPK, choiceDT) {
        // console.log("questionPK:", questionPK);
        const txt = e.target.value;
        // console.log("text:", txt);
        // console.log();

        var textareaAnswersCopy = {...textareaAnswers};

        // let ans = Object.assign(multipleChoiceAnswers);
        textareaAnswersCopy[questionPK] = {
            "question_number": questionPK,
            "type": 2,
            "picked_letter": "",
            "picked_text": `${txt}`,
            "started_time": choiceDT.getTime(),
            "finished_time": new Date().getTime(),
        };
        setTextareaAnswers(textareaAnswersCopy);

        // Developer Note: We need to set the new start date for the `choiceDT`
        // state so know the start date for each question.
        setChoiceDT(new Date());
    }

    if (forceURL !== "") {
        return <Navigate to={forceURL} />;
    }

    return (
        <>
            <h1 className="w3-center w3-text-blue">{name}</h1>
            <h3 className="w3-center w3-text-grey">{alias}</h3>
            <div className="w3-row w3-container">
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l6">
                    <p>{t('This is the answer sheet where you must answer all the questions. Please select the option you feel is correct for each option.')}</p>
                </div>
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l6 w3-container">

                    <div className="w3-white">
                        <ErrorBox errors={errors} />
                    </div>

                    <div className="w3-white w3-round w3-border w3-border-grey w3-card">
                        <h1 className="w3-center w3-text-blue">{t('Questions')}</h1>
                        {/* Developers note: https://www.i18next.com/translation-function/interpolation */}
                        <h3 className="w3-center w3-text-grey">{t('<var1> questions to answer', { var1: mcQuestionsCount })}</h3>

                        <div>
                            {/* Load all the multiple-choice questions. */}
                            {questionPKs.map((questionPK) =>
                                <>{questionPK <= mcQuestionsCount &&
                                    <MultipleChoiceQuestionRow key={`q-${questionPK}`}
                                          questionPK={questionPK}
                                        optionsCount={optionsCount}
                onMultipleChoiceQuestionChoiceChange={onMultipleChoiceQuestionChoiceChange}
                               multipleChoiceAnswers={multipleChoiceAnswers}
                                            tabIndex={tabIndex}
                                    mcQuestionsCount={mcQuestionsCount}
                                            choiceDT={choiceDT}
                                    />
                                }</>
                            )}
                            {/* Load all the text-area questions. */}
                            {questionPKs.map((questionPK) =>
                                <>{questionPK > mcQuestionsCount  &&
                                    <TextAreaQuestionRow key={`q-${questionPK}`}
                                          questionPK={questionPK}
                                        optionsCount={optionsCount}
                        onTextareaQuestionTextChange={onTextareaQuestionTextChange}
                                     textareaAnswers={textareaAnswers}
                                            tabIndex={tabIndex}
                                    mcQuestionsCount={mcQuestionsCount}
                                            choiceDT={choiceDT}
                                    />
                                }</>
                            )}
                        </div>

                        <div className="w3-container w3-center w3-margin">
                            <button type="button"
                                 onClick={onSubmitClick}
                               className="w3-btn w3-mobile w3-teal w3-round-large w3-padding"
                                tabIndex={tabIndex}>
                                    <i className="fa fa-check-circle"></i>&nbsp;{t('SUBMIT')}
                           </button>
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
            </div>
        </>
    );
}

function TextAreaQuestionRow({ questionPK, optionsCount, onTextareaQuestionTextChange, textareaAnswers, tabIndex, mcQuestionsCount, choiceDT }) {
    // The width for the question label.
    const labelWidthScale = 100 / (optionsCount + 1);
    const labelWidthScaleStr = labelWidthScale+"%";

    // The width of the question body.
    const bodyWidthScale = 100 - (100 / (optionsCount + 1));
    const bodyWidthScaleStr = bodyWidthScale+"%";

    return (
        <>
            <div className="w3-margin-bottom">
                <div className="w3-container w3-margin">
                    <div className="w3-row w3-medium">
                        <div className="w3-col w3-center" style={{width:labelWidthScaleStr}}>
                            <button class="w3-button w3-hover-white w3-white" style={{cursor:"pointer"}}>
                                <strong>{questionPK}.</strong>
                            </button>
                        </div>
                        <div className="w3-col w3-center w3-border" style={{width:bodyWidthScaleStr}}>
                            <textarea class="w3-input" onChange={(e, qpk, sdt)=>onTextareaQuestionTextChange(e, questionPK, choiceDT)} rows={15}>
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


function MultipleChoiceQuestionRow({ questionPK, optionsCount, onMultipleChoiceQuestionChoiceChange, multipleChoiceAnswers, tabIndex, mcQuestionsCount, choiceDT }) {
    const start = 1;
    const end = optionsCount;
    const choicePKs = [...Array(end - start + 1).keys()].map(x => x + start);; // https://jasonwatmore.com/post/2021/10/02/vanilla-js-create-an-array-with-a-range-of-numbers-in-a-javascript
    const widthScale = 100 / (optionsCount + 1);
    const widthScaleStr = widthScale+"%";
    return (
        <div className="w3-margin-bottom">
            <div className="w3-container w3-margin">

                <div className="w3-row w3-medium">
                    <div className="w3-col w3-center" style={{width:widthScaleStr}}>
                        <button class="w3-button w3-hover-white w3-white" style={{cursor:"pointer"}}>
                            <strong>{questionPK}.</strong>
                        </button>
                    </div>
                    {choicePKs.map((choicePK) =>
                        <ChoiceCol key={`c-${choicePK}`}
                            questionPK={questionPK}
                              choicePK={choicePK}
                              cssWidth={widthScaleStr}
  onMultipleChoiceQuestionChoiceChange={onMultipleChoiceQuestionChoiceChange}
                 multipleChoiceAnswers={multipleChoiceAnswers}
                              tabIndex={tabIndex}
                              choiceDT={choiceDT}
                        />
                    )}

                </div>


            </div>
        </div>
    );
}


const letters = {
    1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F", 7: "G", 8: "H", 9: "I",
    10: "J", 11: "K", 12: "L", 13: "M", 14: "N", 15: "O", 16: "P", 17: "Q",
    18: "R", 19: "S", 20: "T", 21: "U", 22: "V", 23: "W", 24: "X", 25: "Y", 26: "Z",
}

// https://stackoverflow.com/a/679937
function isEmpty(obj) {
    if (obj === undefined || obj === null || obj === "") {
        return true;
    }
    return Object.keys(obj).length === 0;
}

function ChoiceCol({ questionPK, choicePK, cssWidth, onMultipleChoiceQuestionChoiceChange, multipleChoiceAnswers, tabIndex, choiceDT }) {
    const choiceInfo = multipleChoiceAnswers[questionPK];
    const choiceInfoIsEmpty = isEmpty(choiceInfo);

    let pickedLetter = "";
    if (choiceInfoIsEmpty === false) {
        // console.log(choiceInfo);
        pickedLetter = choiceInfo["picked_letter"];
        // console.log(pickedLetter);
    }

    const letter = letters[choicePK];

    const className = pickedLetter === letter
        ? "w3-btn w3-blue w3-circle w3-border w3-border-light-blue w3-text-white w3-card"
        : "w3-btn w3-light-gray w3-circle w3-border w3-border-blue w3-text-blue";
    // console.log(className);
    return (
        <div className="w3-col w3-center" style={{width:cssWidth}}>
            <button className={className}
                      onClick={(e,i,l,sdt) => onMultipleChoiceQuestionChoiceChange(e, questionPK, choicePK, letter, choiceDT)}
                     tabIndex={tabIndex}>
                      {letter}
            </button>
        </div>
    );
}
