import { React, Component } from "react";
import "./w3.css";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Complete from "./Components/Step6Complete";
import UploadAnswerSheet from "./Components/Step5UploadAnswerSheet";
import AnswerSheet from "./Components/Step4AnswerSheet";
import IdentificationSheet from "./Components/Step3IdentificationSheet";
import EvaluationInfo from "./Components/Step2EvaluationInfo";
import Gateway from "./Components/Step1Gateway";
import ReceiptLaunchpad from "./Components/ReceiptLaunchpad";
import ReceiptSubmitter from "./Components/ReceiptSubmitter";
import NotFoundError from "./Components/NotFoundError";
import TopNavigation from "./Components/TopNavigation";

class AppRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
                <Router>
                    <TopNavigation />
                    <Routes>
                        <Route exact path="/complete" element={<Complete/>}/>
                        <Route exact path="/uploading" element={<UploadAnswerSheet/>}/>
                        <Route exact path="/answer-sheet" element={<AnswerSheet/>}/>
                        <Route exact path="/id" element={<IdentificationSheet/>}/>
                        <Route exact path="/info" element={<EvaluationInfo/>}/>
                        <Route exact path="/receipt" element={<ReceiptLaunchpad/>}/>
                        <Route exact path="/receipt-helper" element={<ReceiptSubmitter/>}/>
                        <Route exact path="/" element={<Gateway/>}/>
                        <Route path="*" element={<NotFoundError/>}/>
                    </Routes>
                    <div>
                        {/* DEVELOPERS NOTE: Mobile menu can go here */}
                    </div>
                </Router>
            </>
        );
    }
}

export default AppRoutes;
