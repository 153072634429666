import React from "react";
import useLocalStorage from "../Hooks/useLocalStorage";


function TopNavigation() {
    const [appTitle] = useLocalStorage("appTitle", "DigitalScantron");
    const [appImageURL] = useLocalStorage("appImageURL", null);
    const [appColour] = useLocalStorage("appColour", "blue");

    console.log("appTitle:", appTitle);
    console.log("appImageURL:", appImageURL);
    console.log("appColour:", appColour);

    return (
        <>
            <div className={`w3-${appColour} w3-top`} style={{height:"70px",}}>
                <div className="w3-bar w3-xxlarge">
                    <span className="w3-bar-item">
                        <div className="w3-hide-small">
                            {appImageURL !== undefined && appImageURL !== null && appImageURL !== "" && appImageURL !== "undefined"
                                ? <>
                                    <img src={appImageURL} class="w3-round" width={45} alt={"Logo"} />&nbsp;{appTitle}
                                </>
                                : <>
                                    <i className="fa fa-qrcode" aria-hidden="true"></i>&nbsp;{appTitle}
                                </>}
                        </div>
                    </span>

                </div>
            </div>
        </>
    );
}

export default TopNavigation;
