import React from "react";
import i18n from '../i18n';
// import { useLocation, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';
import getBrowserMetrics from "../Helpers/browserMetricsHelper";

function useQuery() {
    // https://stackoverflow.com/a/67818378
    return new URLSearchParams(useLocation().search);
}

// Source: https://stackoverflow.com/a/179514
function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}


function Gateway() {
    // Delete any previous cookies that may-or-may-not have been created.
    deleteAllCookies();

    // Clear the local storage when we arrive at the gateway so all previous
    // session information is lost.
    localStorage.removeItem("tenantID");
    localStorage.removeItem("uuid");
    localStorage.removeItem("name");
    localStorage.removeItem("alias");
    localStorage.removeItem("description");
    localStorage.removeItem("imageURL");
    localStorage.removeItem("mcQuestionsCount");
    localStorage.removeItem("taQuestionsCount");
    localStorage.removeItem("questionsCount");
    localStorage.removeItem("optionsCount");
    localStorage.removeItem("submitURL");
    localStorage.removeItem("studentID");
    localStorage.removeItem("studentName");
    localStorage.removeItem("studentCode");
    localStorage.removeItem("schoolName");
    localStorage.removeItem("schoolCode");
    localStorage.removeItem("city");
    localStorage.removeItem("grade");
    localStorage.removeItem("year");
    localStorage.removeItem("numberOfAreas");
    localStorage.removeItem("durationInMinutes");
    localStorage.removeItem("appTitle");
    localStorage.removeItem("appImageURL");
    localStorage.removeItem("appColour");
    localStorage.removeItem("appLanguage");
    localStorage.removeItem("beginTime");
    localStorage.removeItem("endTime");
    localStorage.removeItem("customID");
    localStorage.removeItem("category");
    localStorage.clear();

    // Define the variables we will use for this component.
    let hasError = false;
    let errorMessage = "";
    let tenantID;
    let codeBase64;
    let codeString;
    let codeData;
    let uuid;
    let name;
    let alias;
    let description;
    let imageURL;
    let mcQuestionsCount;
    let taQuestionsCount;
    let optionsCount;
    let submitURL;
    let studentID;
    let studentName;
    let studentCode;
    let schoolName;
    let schoolCode;
    let city;
    let grade;
    let year;
    let numberOfAreas;
    let durationInMinutes;
    let appTitle;
    let appImageURL;
    let appColour;
    let appLanguage;
    let beginTime;
    let endTime;
    let customID;
    let category;

    // Get the URL parameters from our URL path.
    const query = useQuery();

    // Extract the `code` parameter.
    codeBase64 = query.get('code');

    // Convert the `base64` value into our `string` value or error.
    if (codeBase64 === undefined || codeBase64 === null) {
        hasError = true;
        errorMessage = "Missing `code` parameter from the URL.";
    } else if (codeBase64 === "") {
        hasError = true;
        errorMessage = "`code` parameter in URL is missing content.";
    } else {
        codeString = Base64.decode(codeBase64);
    }

    // Convert the `string` value into our `dictionary` or error.
    if (hasError === false) {
        try {
            codeData = JSON.parse(codeString)
        } catch(e) {
            hasError = true;
            errorMessage = "Code content is corrupt.";
        }
    }

    // Extract the required `key` and `value` pairs necessary to run our application or error.
    if (hasError === false) {
        tenantID = codeData["org_id"];
        if (tenantID === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `org_id` key and value.";
        }
        uuid = codeData["uuid"];
        if (uuid === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `uuid` key and value.";
        }
        name = codeData["name"];
        if (name === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `name` key and value.";
        }
        alias = codeData["alias"];
        if (alias === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `alias` key and value.";
        }
        description = codeData["desc"];
        if (description === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `desc` key and value.";
        }
        imageURL = codeData["i_url"];
        if (imageURL === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `i_url` key and value.";
        }
        mcQuestionsCount = codeData["qs"];
        if (mcQuestionsCount === undefined) {
            hasError = true;
            errorMessage = "Multiple-choice question count is missing `qs` key and value.";
        }
        taQuestionsCount = codeData["taqs"];
        if (taQuestionsCount === undefined) {
            hasError = true;
            errorMessage = "Textarea question count is missing `taqs` key and value.";
        }
        optionsCount = codeData["opts"];
        if (optionsCount === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `opts` key and value.";
        }
        beginTime = codeData["s_dt"];
        if (beginTime === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `s_dt` key and value.";
        } else {
            beginTime= new Date(beginTime );
        }
        endTime = codeData["e_dt"];
        if (endTime === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `e_dt` key and value.";
        } else {
            endTime= new Date(endTime);
        }
        submitURL = codeData["s_url"];
        if (submitURL === undefined) {
            hasError = true;
            errorMessage = "Code content is missing `s_url` key and value.";
        }

        studentID = codeData["sid"];
        studentName = codeData["sn"];
        studentCode = codeData["sc"];
        schoolName = codeData["schn"];
        schoolCode = codeData["schc"];
        city = codeData["city"];
        grade = codeData["g"];
        year = codeData["y"];
        numberOfAreas = codeData["noa"];
        durationInMinutes = codeData["d"];

        appTitle = codeData["appt"];
        if (appTitle === undefined || appTitle === null || appTitle === "") {
            appTitle = "Digital Scantron";
        }
        appImageURL = codeData["appi"];
        appColour = codeData["appc"];
        if (appColour === undefined || appColour === null || appColour === "") {
            appColour = "indigo";
        }
        appLanguage = codeData["appl"];
        if (appLanguage === undefined || appLanguage === null || appLanguage === "") {
            appLanguage = "EN";
        }
        appLanguage = appLanguage.toLowerCase();
        customID = codeData["t_id"];
        if (customID === undefined || customID === null || customID === "") {
            customID = "";
        }
        category = codeData["t_c"];
    }

    if (hasError) {
        const browserMetrics = getBrowserMetrics(); // Get browser metrics to attach.
        console.log("tenantID:", browserMetrics.tenantID);
        console.log("appCodeName:", browserMetrics.appCodeName);
        console.log("appName:", browserMetrics.appName);
        console.log("appVersion:", browserMetrics.appVersion);
        console.log("cookieEnabled:", browserMetrics.cookieEnabled);
        console.log("language:", browserMetrics.language);
        console.log("platform:", browserMetrics.platform);
        console.log("product:", browserMetrics.product);
        console.log("userAgent:", browserMetrics.userAgent);
        console.log("screenAvailHeight:", browserMetrics.screenAvailHeight);
        console.log("screenAvailWidth:", browserMetrics.screenAvailWidth);
        console.log("screenColorDepth:", browserMetrics.screenColorDepth);
        console.log("screenHeight:", browserMetrics.screenHeight);
        console.log("screenWidth:", browserMetrics.screenWidth);
        console.log("frontendVersion:", browserMetrics.frontendVersion);
        return (
            <>
                <p>{errorMessage}</p>
            </>
        );
    } else {
        // Save our data to local storage and then redirect to the keypad.
        localStorage.setItem("tenantID", tenantID);
        localStorage.setItem("uuid", uuid);
        localStorage.setItem("name", name);
        localStorage.setItem("alias", alias);
        localStorage.setItem("description", description);
        localStorage.setItem("imageURL", imageURL);
        localStorage.setItem("mcQuestionsCount", mcQuestionsCount);
        localStorage.setItem("taQuestionsCount", taQuestionsCount);
        localStorage.setItem("questionsCount", mcQuestionsCount + taQuestionsCount);
        localStorage.setItem("optionsCount", optionsCount);
        localStorage.setItem("submitURL", submitURL);
        localStorage.setItem("studentID", studentID);
        localStorage.setItem("studentName", studentName);
        localStorage.setItem("studentCode", studentCode);
        localStorage.setItem("schoolName", schoolName);
        localStorage.setItem("schoolCode", schoolCode);
        localStorage.setItem("city", city);
        localStorage.setItem("grade", grade);
        localStorage.setItem("year", year);
        localStorage.setItem("numberOfAreas", numberOfAreas);
        localStorage.setItem("durationInMinutes", durationInMinutes);
        localStorage.setItem("appTitle", appTitle);
        localStorage.setItem("appImageURL", appImageURL);
        localStorage.setItem("appColour", appColour);
        localStorage.setItem("appLanguage", appLanguage);
        localStorage.setItem("beginTime", beginTime);
        localStorage.setItem("endTime", endTime);
        localStorage.setItem("customID", customID);
        localStorage.setItem("category", category);
        // return <Navigate to={`/info`} />;

        i18n.changeLanguage(appLanguage);

        // DEVELOPERS NOTE:
        // We want a hard redirect which will force the page to reload, while not
        // super-efficient, it causes the `TopNavigation` to reload with the
        // latest configuration so it displays correctly.
        window.location = "/info";
        return null;
    }
}

export default Gateway;
