import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "There is a problem": "There is a problem",
      "Error(s)": "Error(s)",
      "Please make sure the above error(s) have been fixed before submitting again": "Please make sure the above error(s) have been fixed before submitting again",
      "Next": "Next",
      "SAVE AND CONTINUE": "SAVE AND CONTINUE",
      "Please enter all the required fields (*) before beginning the evaluation.": "Please enter all the required fields (*) before beginning the evaluation.",
      "My Identification": "My Identification",
      "Student Name": "Student Name",
      "Student Code": "Student Code",
      "School Name": "School Name",
      "School Code": "School Code",
      "Please fill student name.": "Please fill student name.",
      "Please fill student code.": "Please fill student code.",
      "Please fill school name.": "Please fill school name.",
      "Please fill school code.": "Please fill school code.",
      "This is the answer sheet where you must answer all the questions. Please select the option you feel is correct for each option.": "This is the answer sheet where you must answer all the questions. Please select the option you feel is correct for each option.",
      "Questions": "Questions",
      "<var1> questions to answer": "{{var1}} questions to answer",
      "SUBMIT": "SUBMIT",
      "Not all questions were answered, please make sure you have answered all the questions before proceeding to submit the evaluation.": "Not all questions were answered, please make sure you have answered all the questions before proceeding to submit the evaluation.",
      "Sending, please wait...": "Sending, please wait...",
      "Submitted": "Submitted",
      "You have successfully sent your answer sheet to the server. Please print a copy of this page for your personal records.": "You have successfully sent your answer sheet to the server. Please print a copy of this page for your personal records.",
      "Date Submitted": "Date Submitted",
      "School": "School",
      "Student": "Student",
      "Evaluation": "Evaluation",
      "Started": "Started",
      "Finished": "Finished",
      "Total Questions": "Total Questions",
      "Answers": "Answers",
      "RECEIPT CODE": "RECEIPT CODE",
      "CLICK THIS TO SUBMIT ELECTRONICALLY": "CLICK THIS TO SUBMIT ELECTRONICALLY",
    }
  },
  es: {
    translation: {
      "There is a problem": "Hay un problema",
      "Error(s)": "Errores",
      "Please make sure the above error(s) have been fixed before submitting again": "Asegúrese de que los errores anteriores se hayan solucionado antes de volver a enviar",
      "Next": "Continuar",
      "SAVE AND CONTINUE": "CONTINUAR A LAS PREGUNTAS",
      "Please enter all the required fields (*) before beginning the evaluation.": "Valida tus datos antes de continuar.",
      "My Identification": "¡Tus datos!",
      "Student Name": "Estudiante",
      "Student Code": "Código",
      "School Name": "Colegio",
      "School Code": "Código de Colegio",
      "Please fill student name.": "Please fill student name.",
      "Please fill student code.": "Please fill student code.",
      "Please fill school name.": "Please fill school name.",
      "Please fill school code.": "Please fill school code.",
      "This is the answer sheet where you must answer all the questions. Please select the option you feel is correct for each option.": "Selecciona la opción de respuesta que consideres correcta.",
      "Questions": "Preguntas",
      "<var1> questions to answer": "Total por responder: {{var1}}", // Note: https://www.i18next.com/translation-function/interpolation
      "SUBMIT": "SUBMIT",
      "Not all questions were answered, please make sure you have answered all the questions before proceeding to submit the evaluation.": "No se respondieron todas las preguntas, asegúrese de haber respondido todas las preguntas antes de proceder a enviar la evaluación",
      "Sending, please wait...": "Sending, please wait...",
      "Submitted": "¡Has finalizado!",
      "You have successfully sent your answer sheet to the server. Please print a copy of this page for your personal records.": "Descarga tu certificado de aplicación.",
      "Date Submitted": "Fecha de realización",
      "School": "Colegio",
      "Student": "Estudiante",
      "Evaluation": "Prueba",
      "Started": "Fecha de inicio",
      "Finished": "Fecha de finalización",
      "Total Questions": "Total de preguntas",
      "Answers": "Respuestas seleccionadas",
      "RECEIPT CODE": "RECEIPT CODE",
      "CLICK THIS TO SUBMIT ELECTRONICALLY": "CLICK THIS TO SUBMIT ELECTRONICALLY",
    }
  }
};

const fallbackLng = ['en'];
const availableLanguages = ['en', 'es'];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng, // fallback language is english.
    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
});

export default i18n;
