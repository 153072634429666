import React, { useState, useEffect } from "react";
import postSubmitAPI from "../API/Submission";
import { ErrorBox } from "./ErrorBox";
import { isEmpty } from 'lodash';
import { Base64 } from 'js-base64';

function ReceiptLaunchpad() {
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    let hasError = false;
    let errorMessage = "";
    let codeString;
    let codeData;
    let submitURL;

    // Get the URL parameters from our local storage and extract the `code` parameter.
    const codeBase64 = localStorage.getItem("codePayloadB64");

    // Convert the `base64` value into our `string` value or error.
    if (codeBase64 === undefined || codeBase64 === null) {
        hasError = true;
        errorMessage = "Missing `code` parameter from the URL.";
    } else if (codeBase64 === "") {
        hasError = true;
        errorMessage = "`code` parameter in URL is missing content.";
    } else {
        codeString = Base64.decode(codeBase64);
    }

    // Convert the `string` value into our `dictionary` or error.
    if (hasError === false) {
        try {
            codeData = JSON.parse(codeString)
        } catch(e) {
            hasError = true;
            errorMessage = "Code content is corrupt.";
        }
    }

    // Extract the required `key` and `value` pairs necessary to run our application or error.
    submitURL = codeData["submit_url"];
    if (submitURL === undefined) {
        hasError = true;
        errorMessage = "Code content is missing `submit_url` key and value.";
    }

    useEffect(() => {
        if (hasError === false) {
            postSubmitAPI(
                submitURL,
                codeData,
                (responseData) => {
                    console.log("postReceiptAPI|responseData:", responseData);
                }, (apiErrors) => {
                    console.log("postReceiptAPI|apiErrors:", apiErrors);
                    setErrors(apiErrors);
                }, () => {
                    console.log("postReceiptAPI|done");
                    setIsLoading(false);
                }
            );
        }
    },[hasError, submitURL, codeData, setIsLoading,]);

    // Any formatting errors should display this simple GUI.
    if (hasError) {
        return <p>{errorMessage}</p>;
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m3 w3-container"></div>
                <div className="w3-col m6">
                    <ErrorBox errors={errors} />
                    {isLoading
                        ? <div style={{width:"100%"}} className="w3-margin-top">
                            <div>
                                <div className="w3-white w3-center w3-padding w3-round-xlarge w3-border w3-border-grey">
                                    <i className="fa fa-spinner w3-spin w3-jumbo"></i>
                                    <h1><strong>Sending receipt, please wait...</strong></h1>
                                </div>
                            </div>
                        </div>
                        : <>
                            {isEmpty(errors)
                                ? <div className="w3-row w3-margin-top">
                                    <div className="w3-col m3 w3-container"></div>
                                    <div className="w3-col m6">
                                        <div style={{width:"100%"}} className="w3-margin-top">
                                            <div>
                                                <div className="w3-white w3-center w3-padding w3-round-xlarge w3-border w3-border-grey">
                                                    <i className="fa fa-check-circle w3-jumbo w3-text-green"></i>
                                                    <h1><strong>Receipt Submitted</strong></h1>
                                                    <p>
                                                    You have successfully submitted your receipt. Our system has received your evaluation and will proceed to mark it.
                                                    </p>
                                                    <p>
                                                    Thank you and have a great day!
                                                    </p>
                                                    <p>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w3-col m3 w3-container"></div>
                                </div>
                                : <div class="w3-panel w3-pale-red w3-round-xlarge">
                                    <h3>Oops, looks like something went wrong...</h3>
                                    <p>We appoligies but there appears to be a problem on our end.</p>
                                    <ErrorBox errors={errors} />
                                </div>
                            }
                        </>
                    }

                </div>
                <div className="w3-col m3 w3-container"></div>
            </div>

        </>
    );
}

export default ReceiptLaunchpad;
