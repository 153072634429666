import axios from "axios";


export default function postSubmitAPI(aURL, data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    console.log("postSubmitAPI: Sending ...");
    axios.post(aURL, data).then((successResponse) => {
        console.log("postSubmitAPI: Success");
        onSuccessCallback({
            "message": "success",
        });
    }).catch( (exception) => {
        console.log("postSubmitAPI: Error");
        try {
            onErrorCallback(exception.response.data);
        } catch (e) {
            console.log("postSubmitAPI: APP Error Detected");
            console.log("postSubmitAPI:", e);
            onErrorCallback(e);
        }
    }).then(onDoneCallback);
}
