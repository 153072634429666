import React from "react";

function NotFoundError() {
    return (
        <>
            <h1>404 - Page does not exist</h1>
        </>
    );
}

export default NotFoundError;
